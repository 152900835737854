export const firebaseConfig = {
  apiKey: "AIzaSyAkkN2RU6vOYpjFFDJEjtkAtJY_nVgx9iY",
  authDomain: "stars-ad36d.firebaseapp.com",
  projectId: "stars-ad36d",
  storageBucket: "stars-ad36d.appspot.com",
  messagingSenderId: "582009438074",
  appId: "1:582009438074:web:4b9bec7e81f5870677a0c3",
  measurementId: "G-NMW6MBDXRB",
};

export default firebaseConfig;
