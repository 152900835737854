import React from "react";
import { Link, useHistory } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "./NavMobile.module.scss";

const cx = classNames.bind(styles);

export const NavMobile = () => {
  const history = useHistory();

  const handleLogo = () => {
    history.push("/");
  };

  return (
    <div className={cx("nav--mobile")}>
      <div className={cx("menu")}>
        <Link to="/about" className={cx("menu__link")}>
          about
        </Link>
        <Link to="/faq" className={cx("menu__link")}>
          faq
        </Link>
        <div className={cx("logo-spacer")} />
        <Link to="/universe" className={cx("menu__link", "menu__link--active")}>
          explore
        </Link>
        <Link to="/question1" className={cx("menu__link")}>
          write
        </Link>
      </div>
      <div className={cx("logo")} onClick={handleLogo} />
    </div>
  );
};

export default NavMobile;
