import React, { FC } from "react";
import Nav from "~/components/Nav";
import classNames from "classnames/bind";
import styles from "./PageTemplate.module.scss";
import NavMobile from "~/components/NavMobile";

const cx = classNames.bind(styles);

const PageTemplate: FC = ({ children }) => {
  return (
    <div>
      <Nav />
      <NavMobile />
      <div className={cx("wrapper")}>
        <div className={cx("wrapperBlock")}>{children}</div>
      </div>
    </div>
  );
};

export default PageTemplate;
