import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/firestore";
import firebaseConfig from "./firebase.config";
import Hello from "./screens/Hello";
import Question1 from "./screens/Question1";
import KeepInTouch from "./screens/KeepInTouch";
import Universe from "./screens/Universe";
import QuestionIntro from "./screens/QuestionIntro";
import StarField from "./components/StarField";
import VerifyEmail from "~/screens/VerifyEmail";
import Help from "~/screens/Help";

firebase.initializeApp(firebaseConfig);
firebase.analytics();

const redirectToUniverseForNow = false;

function App() {
  const [answer, setAnswer] = useState("");

  const clearInputs = () => {
    // setName("");
    setAnswer("");
  };

  return (
    <div className="starfield-container">
      <StarField
        style={{
          width: "100%",
          height: "100vh",
          padding: 0,
          margin: 0,
          position: "fixed",
        }}
      />
      <div className="starfield-content">
        <Router>
          <Switch>
            <Route exact path="/">
              {redirectToUniverseForNow ? <Redirect to="/universe" /> : <Hello />}
            </Route>
            <Route exact path="/about">
              <QuestionIntro />
            </Route>
            <Route exact path="/faq">
              <Help />
            </Route>
            <Route exact path="/question1">
              <Question1 answer={answer} setAnswer={setAnswer} />
            </Route>
            <Route exact path="/keep-in-touch">
              <KeepInTouch answer={answer} />
            </Route>
            <Route exact path="/universe">
              <Universe />
            </Route>
            <Route exact path="/verify-email">
              <VerifyEmail />
            </Route>
          </Switch>
        </Router>
      </div>
    </div>
  );
}

export default App;
