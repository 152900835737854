import React, { useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames/bind";
import shuffle from "lodash/shuffle";
import Modal from "react-modal";
import { ForceGraph3DInstance } from "3d-force-graph";
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass.js";
import { Renderer, Vector2, Vector3 } from "three";
import { CSS3DRenderer, CSS3DSprite } from "three/examples/jsm/renderers/CSS3DRenderer";
import { ForceGraph3D } from "react-force-graph";
import Nav from "~/components/Nav";
import styles from "./Universe.module.scss";
import NavMobile from "~/components/NavMobile";
import firebase from "firebase/app";
import "firebase/functions";
import "firebase/firestore";
import "firebase/auth";
import PageTemplate from "~/components/PageTemplate";
import {
  Link,
  Lune,
  NodeCustom,
  ProcessedData,
  ProcessedLune,
} from "~/screens/Universe.types";

const cx = classNames.bind(styles);

const bloomPass = new UnrealBloomPass(new Vector2(256, 256), 3, 1, 0.05);
// bloomPass.strength = 3;
// bloomPass.radius = 1;
// bloomPass.threshold = 0.1;
// bloomPass.resolution = new Vector2(256, 256);

const extraRenderers = [(new CSS3DRenderer() as unknown) as Renderer];

const customModalStyles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    zIndex: 100,
  },
  content: {
    borderRadius: 0,
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    padding: "20px 20px 40px 20px",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    minHeight: 300,
    maxWidth: "90%",
  },
};

const getPrefixOfId = (id: string | undefined = "") =>
  id.indexOf("|") !== -1 ? id.split("|")[0] : "";

const prefixIdWithPrefix = (idWithoutPrefix: number | string, prefix: string) =>
  `${prefix}|${idWithoutPrefix}`;

const generateNodeThreeObject = (node: any) => {
  // console.log("node:", node);
  const nodeCustom = node as NodeCustom;

  const nodeEl = document.createElement("div");
  // nodeEl.textContent = "test";
  nodeEl.textContent = nodeCustom.label;
  // nodeEl.style.color = node.color;
  nodeEl.className = cx("node-label", {
    "node-label--hidden": nodeCustom.inGroup === 0,
  });
  return new CSS3DSprite(nodeEl);
};

// Number of layout engine cycles to dry-run at ignition before starting to render.
const warmupTicks = 0;

// Ms to animate camera to focus on selected node.
const focusAnimationDuration = 3000;

const randomLoneStarMessagesSeed = [
  "This star hasn't made connections with others yet. Check again later?",
  "Do you relate to this word? Tell us about it.",
  "Write a message with this word and perhaps this single star might form something.",
  "Someone wrote this a while ago. Interesting, isn't it?",
  "A single star shining. A word. What does it mean?",
];
let randomLoneStarMessages = shuffle(randomLoneStarMessagesSeed);

const UniverseInternal = ({
  processedData,
  processedLunes,
}: {
  processedData: ProcessedData;
  processedLunes: ProcessedLune[];
}) => {
  const [luneViewIsOpen, setLuneViewIsOpen] = useState(false);
  const [activeLune, setActiveLune] = useState<ProcessedLune>();
  const loneStarMessageIndex = useRef(0);
  const graphRef = useRef<ForceGraph3DInstance>();
  const luneDelayTimerRef = useRef<number>();

  const getNodeById = useCallback(
    (id: string): NodeCustom | undefined =>
      processedData.nodes.find((node) => node.id === id),
    [processedData],
  );

  const findLuneByPrefix = useCallback(
    (prefix: string) => {
      return processedLunes.find(
        (lune) => `${lune.rootWord.word}(${lune.rootWord.tag})` === prefix,
      );
    },
    [processedLunes],
  );

  const handleFocusOnNode = useCallback(
    (node: any) => {
      if (!graphRef.current) {
        return;
      }

      // Aim at node from outside it
      const distance = 120;
      const distRatio = 1 + distance / Math.hypot(node.x, node.y, node.z);
      console.log("distRatio:", distRatio);

      const cameraPosition = graphRef.current.camera().position;
      const distanceToCamera = cameraPosition.distanceTo(
        new Vector3(node.x, node.y, node.z),
      );

      console.log(
        "cameraPosition:",
        cameraPosition,
        ", distanceToCamera:",
        distanceToCamera,
      );

      const transitionDuration = distanceToCamera > 500 ? focusAnimationDuration : 400;

      graphRef.current.cameraPosition(
        { x: node.x * distRatio, y: node.y * distRatio, z: node.z * distRatio }, // new position
        node, // lookAt ({ x, y, z })
        transitionDuration, // ms transition duration
      );

      return transitionDuration;
    },
    [graphRef],
  );

  const displayLuneForNode = (node: NodeCustom) => {
    const prefix = getPrefixOfId(node.id);
    const lune = findLuneByPrefix(prefix);
    console.log("Lune:", lune);
    setActiveLune(lune);
    setLuneViewIsOpen(true);
  };

  const handleNodeClick = (node: any) => {
    if (!node.id) {
      // One of background stars.
      return;
    }

    const transitionDuration = handleFocusOnNode(node);
    const nodeCustom = node as NodeCustom;
    console.log("[handleNodeClick] node:", nodeCustom);

    if (luneDelayTimerRef.current) {
      clearTimeout(luneDelayTimerRef.current);
    }

    luneDelayTimerRef.current = setTimeout(() => {
      displayLuneForNode(nodeCustom);
    }, transitionDuration);
  };

  const handleLinkClick = (link: any) => {
    console.log("link:", link);

    const prefix = getPrefixOfId(link?.source?.id);
    const lune = findLuneByPrefix(prefix);
    console.log("lune:", lune);

    if (lune && lune.rootWord) {
      const node = getNodeById(prefixIdWithPrefix(lune.rootWord.id, prefix));
      if (node) {
        handleNodeClick(node);
      }
    }
  };

  const handleCloseLune = () => {
    setLuneViewIsOpen(false);
  };

  useEffect(() => {
    if (graphRef.current) {
      graphRef.current.postProcessingComposer().addPass(bloomPass);
    }
  }, [graphRef]);

  const [highlightNodes, setHighlightNodes] = useState(new Set());
  const [highlightLinks, setHighlightLinks] = useState(new Set());
  const [hoverNode, setHoverNode] = useState(null);

  const updateHighlight = () => {
    setHighlightNodes(highlightNodes);
    setHighlightLinks(highlightLinks);
  };

  const handleNodeHover = (node: any) => {
    const nodeCustom = node as NodeCustom;
    console.log("[handleNodeHover] node:", nodeCustom);

    highlightNodes.clear();
    highlightLinks.clear();
    if (node) {
      highlightNodes.add(node);
    }

    setHoverNode(node || null);
    updateHighlight();
  };

  const randomLoneStarMessage = () => {
    loneStarMessageIndex.current = loneStarMessageIndex.current + 1;
    if (loneStarMessageIndex.current >= randomLoneStarMessagesSeed.length) {
      randomLoneStarMessages = shuffle(randomLoneStarMessagesSeed);
      loneStarMessageIndex.current = 0;
    }
    return randomLoneStarMessages[loneStarMessageIndex.current];
  };

  return (
    <div>
      <div>
        <ForceGraph3D
          ref={graphRef}
          extraRenderers={extraRenderers}
          graphData={processedData}
          enableNodeDrag={false}
          onNodeHover={handleNodeHover}
          onNodeClick={handleNodeClick}
          onLinkClick={handleLinkClick}
          nodeThreeObjectExtend
          nodeThreeObject={generateNodeThreeObject}
          warmupTicks={warmupTicks}
        />
      </div>

      <Modal isOpen={luneViewIsOpen} style={customModalStyles} contentLabel="lune">
        <div className={cx("lune-modal")}>
          <div className={cx("lune-container")}>
            {luneViewIsOpen && !activeLune && <p>{randomLoneStarMessage()}</p>}
            {activeLune && activeLune.lines.map((line) => <p key={line}>{line}</p>)}
          </div>
          <button className={cx("close-lune")} onClick={handleCloseLune}>
            Close
          </button>
        </div>
      </Modal>
    </div>
  );
};

const Universe = () => {
  const [processedData, setProcessedData] = useState<ProcessedData>();
  const [processedLunes, setProcessedLunes] = useState<ProcessedLune[]>();

  const loadData = async () => {
    const dataRef = await firebase
      .firestore()
      .collection("processed")
      .doc("latest")
      .get();
    const { graph: graphJson, lunes: lunesJson } = (dataRef.data() as unknown) as {
      graph: string;
      lunes: string;
    };

    const graph = JSON.parse(graphJson) as { nodes: NodeCustom[]; links: Link[] };
    const lunes = JSON.parse(lunesJson) as Lune[];
    console.log("[loadData] graph:", graph, ", lunes:", lunes);

    const nodesWithVal = graph.nodes.map((node) => {
      let value = 0.01;

      if (node.inGroup === 0) {
        // These are associating nodes.
        value = 0.01;
      } else if (node.inGroup === -1) {
        // These are background nodes.
        value = 0.1;
      } else {
        // These are used nodes.
        value = 0.01 * node.freq * node.freq;
      }

      return {
        ...node,
        val: value,
      };
    });

    // Add some background stars.
    // const numberOfBackgroundStars = 200;
    // for (let i = 0; i < numberOfBackgroundStars; ++i) {
    //   nodesWithVal.push({
    //     val: 0.01,
    //   });
    // }

    const processedData = { nodes: nodesWithVal, links: graph.links };
    setProcessedData(processedData);

    const processedLunes = lunes.map(
      ({ templateLabel, text, terms, rootWord }) =>
        ({
          templateLabel,
          text,
          terms,
          rootWord,
          lines: text.split("\n"),
        } as ProcessedLune),
    );
    setProcessedLunes(processedLunes);
  };

  useEffect(() => {
    loadData();
  }, []);

  if (!processedData || !processedLunes) {
    return (
      <PageTemplate>
        <div
          style={{
            textAlign: "center",
            padding: "40px 0",
          }}
        >
          Loading
        </div>
      </PageTemplate>
    );
  }

  return (
    <div className={cx("universe-screen")}>
      <UniverseInternal processedData={processedData} processedLunes={processedLunes} />
      <Nav />
      <NavMobile />
    </div>
  );
};

export default Universe;
