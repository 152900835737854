import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames/bind";
import Swal from "sweetalert2";
import firebase from "firebase/app";
import "firebase/functions";
import "firebase/auth";
import styles from "./KeepInTouch.module.scss";
import PageTemplate from "~/components/PageTemplate";

const cx = classNames.bind(styles);

const getVerifyRedirectionUrl = (submissionId: string) => {
  return `https://if-stars-could-talk.netlify.app/verify-email?submissionId=${submissionId}`;
};

enum KeepInTouchStates {
  INITIAL = "initial",
  SUBMITTING = "submitting",
  SUBMITTED = "submitted",
  SENDING_VERIFICATION_EMAIL = "sending_verification_email",
  SENT_VERIFICATION_EMAIL = "sent_verification_email",
}

interface SubmissionResponse {
  isError: boolean;
  newKey: string;
  errorCode?: string;
}

const KeepInTouch = ({ answer }: { answer: string }) => {
  const history = useHistory();
  const [state, setState] = useState(KeepInTouchStates.INITIAL);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [submissionId, setSubmissionId] = useState<string>();
  const [submissionResult, setSubmissionResult] = useState<SubmissionResponse>();
  const [recaptchaToken, setRecaptchaToken] = useState<string>("");

  // Redirect to home if no answer.
  useEffect(() => {
    if (!answer) {
      history.push("/");
      return;
    }
  }, []);

  const runStateMachine = async (newState: KeepInTouchStates) => {
    if (state === KeepInTouchStates.INITIAL) {
      // Reset various states.
      // captchaRef.current.reset();
      return;
    }
    if (state === KeepInTouchStates.SUBMITTING) {
      const addSubmissionFunction = firebase.functions().httpsCallable("addSubmission");

      // TODO: Decide if we need recaptcha.
      const data = {
        recaptchaToken,
        submission: {
          name,
          email,
          answer,
        },
      };
      console.log("data:", data);

      Swal.fire({
        icon: "info",
        text: "Submitting",
        allowOutsideClick: false,
      }).then();
      Swal.showLoading();

      const result: { data: SubmissionResponse } = await addSubmissionFunction(data);
      console.log("result:", result);
      setSubmissionResult(result.data);
      setState(KeepInTouchStates.SUBMITTED);
      return;
    }
    if (state === KeepInTouchStates.SUBMITTED) {
      if (!submissionResult) {
        return;
      }
      const { isError, newKey: submissionId } = submissionResult;
      if (isError || !submissionId) {
        setState(KeepInTouchStates.INITIAL);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong! Please try again",
        }).then();
        return;
      }

      // Proceed to send verification email.
      setSubmissionId(submissionId);
      setState(KeepInTouchStates.SENDING_VERIFICATION_EMAIL);
    }
    if (state === KeepInTouchStates.SENDING_VERIFICATION_EMAIL) {
      if (!submissionId) {
        return;
      }
      const actionCodeSettings = {
        // URL you want to redirect back to. The domain (www.example.com) for this
        // URL must be in the authorized domains list in the Firebase Console.
        url: getVerifyRedirectionUrl(submissionId),
        // This must be true.
        handleCodeInApp: true,
        // iOS: {
        //   bundleId: "com.if-stars-could-talk.ios",
        // },
        // android: {
        //   packageName: "com.if-stars-could-talk.android",
        //   installApp: true,
        //   minimumVersion: "12",
        // },
        // dynamicLinkDomain: "if-stars-could-talk.netlify.app",
      };

      Swal.update({
        icon: "info",
        text: "Sending verification email",
        allowOutsideClick: false,
      });
      Swal.showLoading();

      firebase
        .auth()
        .sendSignInLinkToEmail(email, actionCodeSettings)
        .then(() => {
          // The link was successfully sent. Inform the user.
          // Save the email locally so you don't need to ask the user for it again
          // if they open the link on the same device.
          window.localStorage.setItem("emailForSignIn", email);
          setState(KeepInTouchStates.SENT_VERIFICATION_EMAIL);
          Swal.fire(
            "Verification email sent!",
            "Please check your email for verification",
            "success",
          ).then(() => {
            handleSuccess();
          });
        })
        .catch((error) => {
          const { code: errorCode, message: errorMessage } = error;
          console.error("Could not set email login link.", error);
          setState(KeepInTouchStates.INITIAL);

          if (errorCode === "auth/invalid-email") {
            Swal.fire("", "Invalid email!", "error").then();
            return;
          }
          Swal.fire(
            "",
            "Could not set email login link, please try again!",
            "error",
          ).then();
          return;
        });
      return;
    }
  };

  useEffect(() => {
    runStateMachine(state).then();
  }, [state]);

  const handleSubmit = async () => {
    setState(KeepInTouchStates.SUBMITTING);
  };

  const handleSuccess = () => {
    history.push("/universe");
  };

  const isValid = name.length > 0 && email.length > 0;
  const shouldDisableInputs = state !== KeepInTouchStates.INITIAL;

  return (
    <PageTemplate>
      <div className={cx("keepintouch-screen")}>
        <div>
          <div className={cx("question")}>How do we contact you?</div>

          <form>
            <div className="mb24">
              <input
                placeholder="Display Name"
                disabled={shouldDisableInputs}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="mb24">
              <input
                placeholder="Email"
                disabled={shouldDisableInputs}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <p>
              Please check your email to verify that you are human. Your email address
              will be kept strictly confidential.
            </p>
          </form>
        </div>

        <div className="mt32">
          {state === KeepInTouchStates.INITIAL && (
            <button
              onClick={handleSubmit}
              disabled={!isValid}
              className={cx("next-button")}
            >
              Continue to Universe
            </button>
          )}
          {state !== KeepInTouchStates.INITIAL && (
            <button disabled className={cx("next-button")}>
              Please wait
            </button>
          )}
          {/*{state === KeepInTouchStates.SUBMITTING && (*/}
          {/*  <button className={cx("next-button")}>Sending email...</button>*/}
          {/*)}*/}
          {/*{state === KeepInTouchStates.SUBMITTED && (*/}
          {/*  <button className={cx("next-button")}>Verifying submission...</button>*/}
          {/*)}*/}
          {/*{state === KeepInTouchStates.SENDING_VERIFICATION_EMAIL && (*/}
          {/*  <button className={cx("next-button")}>Sending email...</button>*/}
          {/*)}*/}
        </div>
      </div>
    </PageTemplate>
  );
};

export default KeepInTouch;
