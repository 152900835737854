import React from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "./QuestionIntro.module.scss";
import PageTemplate from "~/components/PageTemplate";

const cx = classNames.bind(styles);

const QuestionIntro = () => {
  const history = useHistory();

  const handleNext = () => {
    history.push("/question1");
  };

  return (
    <PageTemplate>
      <div className={cx("textBlock")}>
        <h3>
          <i>If Stars Could Talk</i> is a project about hopes and dreams. We collect
          messages written by you and map them onto our night sky.
        </h3>
        <h3>
          Your words will connect with others, forming new constellations and
          interpretations of the future.
        </h3>
      </div>
    </PageTemplate>
  );
};

export default QuestionIntro;
