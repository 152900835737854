import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames/bind";
import { Link, useHistory } from "react-router-dom";
import { Element, Events, Link as ScrollLink, scroller, scrollSpy } from "react-scroll";
import styles from "./Hello.module.scss";
import StarField from "~/components/StarField";
import Nav from "~/components/Nav";
import NavMobile from "~/components/NavMobile";
import FooterMobile from "~/components/FooterMobile";

const cx = classNames.bind(styles);

const Hello = () => {
  const history = useHistory();
  const activeSection = useRef("logo");
  const [showScrollDownButton, setShowScrollDownButton] = useState(true);

  useEffect(() => {
    scrollSpy.update();
  }, []);

  const handleLogoIsActive = () => {
    console.log("handleLogoIsActive");
    activeSection.current = "logo";
    setShowScrollDownButton(true);
  };

  const handleAboutIsActive = () => {
    console.log("handleAboutIsActive");
    activeSection.current = "about";
    setShowScrollDownButton(true);
  };

  const handleNextIsActive = () => {
    console.log("handleNextIsActive");
    activeSection.current = "next";
    setShowScrollDownButton(false);
  };

  const handleScrollDown = () => {
    let target = "logo";

    if (activeSection.current === "logo") {
      target = "about";
    } else if (activeSection.current === "about") {
      target = "next";
    }

    const goToContainer = new Promise<void>((resolve, reject) => {
      Events.scrollEvent.register("end", () => {
        resolve();
        Events.scrollEvent.remove("end");
      });

      scroller.scrollTo(target, {
        duration: 2000,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    });

    goToContainer.then(() => console.log("Scroll done."));
  };

  const handleGotoWrite = () => {
    history.push("/question1");
  };

  const handleGotoRead = () => {
    history.push("/universe");
  };

  return (
    <div>
      <Element name="logo">
        <div className={cx("section", "section-logo")}>
          <div className={cx("logo")} />
        </div>
      </Element>
      <div
        style={{
          position: "sticky",
          top: 0,
          height: "100vh",
          marginBottom: "-100vh",
          width: 50,
        }}
      >
        <Nav />
      </div>

      <div
        style={{
          position: "sticky",
          top: 0,
          height: 100,
          marginBottom: -100,
        }}
      >
        <NavMobile />
      </div>
      {/*<div*/}
      {/*  style={{*/}
      {/*    position: "sticky",*/}
      {/*    top: "100vh",*/}
      {/*    width: "100%",*/}
      {/*    height: 110,*/}
      {/*    marginBottom: -110,*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <div*/}
      {/*    style={{*/}
      {/*      marginTop: -110,*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <FooterMobile />*/}
      {/*  </div>*/}
      {/*</div>*/}
      <Element name="about">
        <div className={cx("section", "section-about")}>
          <div className={cx("about-wrapper")}>
            <h3>
              <i>If Stars Could Talk</i> is a project about hopes and dreams. We collect
              messages written by you and map them onto our night sky.
            </h3>
            <h3>
              Your words will connect with others, forming new constellations and
              interpretations of the future.
            </h3>
          </div>
        </div>
      </Element>
      <Element name="next">
        <div className={cx("section", "section-next")}>
          <h3 style={{ marginBottom: 56 }}>What would you like to do?</h3>
          <div style={{ display: "flex" }}>
            <button className={cx("write-button")} onClick={handleGotoWrite}>
              Write
            </button>
            <button className={cx("read-button")} onClick={handleGotoRead}>
              Explore
            </button>
          </div>
        </div>
      </Element>
      <div className={cx("hidden-links")}>
        <ScrollLink to={"logo"} spy onSetActive={handleLogoIsActive} />
        <ScrollLink to={"about"} spy onSetActive={handleAboutIsActive} />
        <ScrollLink to={"next"} spy onSetActive={handleNextIsActive} />
      </div>
      {showScrollDownButton && (
        <div className={cx("scroll-down")} onClick={handleScrollDown}>
          <div />
        </div>
      )}
    </div>
  );
};

export default Hello;
