import { useEffect } from "react";
import Swal from "sweetalert2";
import firebase from "firebase/app";
import "firebase/functions";
import PageTemplate from "~/components/PageTemplate";
import useQuery from "~/utils/useQuery";
import { useHistory } from "react-router-dom";

const VerifyEmail = () => {
  const query = useQuery();
  const history = useHistory();

  const verifySubmissionFunction = firebase.functions().httpsCallable("verifySubmission");

  const verifySubmission = async () => {
    const submissionId = query.get("submissionId");

    if (!submissionId) {
      await Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Your star could not be found. Please try clicking the verify link again.",
      });
      return;
    }
    const data = { submissionId };

    const result: any = await verifySubmissionFunction(data);
    console.log("result:", result);

    const { isError, errorCode } = result.data;
    if (isError) {
      if (errorCode === "NOT_FOUND") {
        await Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "The submission you are trying to verify does not exist.",
        });
        return;
      }
      if (errorCode === "ALREADY_VERIFIED") {
        await Swal.fire("", "Your submission has already been verified.", "success");

        history.push("/universe");
        return;
      }

      // No handled error code.
      await Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong! Please try again.",
      });
      return;
    }

    await Swal.fire("", "Your submission is added to the universe!", "success");

    history.push("/universe");
  };

  // Show alert on mount.
  useEffect(() => {
    verifySubmission().then();
  }, []);
  return (
    <PageTemplate>
      <h1>Please wait while we verify your submission...</h1>
    </PageTemplate>
  );
};

export default VerifyEmail;
