import React from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "./Help.module.scss";
import PageTemplate from "~/components/PageTemplate";

const cx = classNames.bind(styles);

const QuestionIntro = () => {
  const history = useHistory();

  return (
    <PageTemplate>
      <div className={cx("textBlock")}>
        <h4>How are the stars created?</h4>
        <p>
          The stars are words which people used to describe their future. To write about{" "}
          <i>your</i> future, click 'Write' in the menu.
        </p>
        <h4>What are the constellations?</h4>
        <p>
          A constellation is a group of words that relate to one another. As more people
          imagine and write about their future, new constellations will form and shape
          this world.
        </p>
        <h4>Are the stars talking?</h4>
        <p>Click on each constellation to read what the stars are talking about.</p>
        {/* <h4>How do I explore the universe?</h4>
        <p>
          On the desktop: Left-click to rotate, middle-click/wheel to zoom, and
          right-click to pan. On the phone: Use one finger to rotate, two fingers pinch
          in/out to zoom, and drag two fingers to pan.
        </p> */}
      </div>
    </PageTemplate>
  );
};

export default QuestionIntro;
