import React from "react";
import { Link, useHistory } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "./Nav.module.scss";

const cx = classNames.bind(styles);

export const Nav = () => {
  const history = useHistory();

  const handleLogo = () => {
    history.push("/");
  };

  return (
    <div className={cx("nav")}>
      <div className={cx("logo")} onClick={handleLogo} />
      <div className={cx("credits-footer")} />
      <div className={cx("menu")}>
        <ul>
          <li>
            <Link to="/universe" className={cx("menu__link")}>
              explore
            </Link>
          </li>
          <li>
            <Link to="/question1" className={cx("menu__link")}>
              write
            </Link>
          </li>
          <li>
            <Link to="/about" className={cx("menu__link")}>
              about
            </Link>
          </li>
          <li>
            <Link to="/faq" className={cx("menu__link")}>
              faq
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Nav;
