import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "./Question1.module.scss";
import PageTemplate from "~/components/PageTemplate";

const cx = classNames.bind(styles);

const maxAnswerLength = 600;

const Question1 = ({
  answer,
  setAnswer,
}: {
  answer: string;
  setAnswer: (answer: string) => void;
}) => {
  const history = useHistory();
  const currentPromptIndex = useRef(0);
  const [prompts, setPrompts] = useState([
    "Tell us what you see in 2050.",
    "What is the future like?",
    "How do you imagine our future?",
  ]);

  const handleNext = () => {
    history.push("/keep-in-touch");
  };

  const handleChange = (newAnswer: string) => {
    setAnswer(newAnswer);
  };

  const hints = [
    "Think about these questions for your answer...",
    "Are there flying cars?",
    "What's happening around you?",
    "Is there someone beside you?",
    "Are you satisfied with your life?",
    "What are you wearing?",
    "What colors do you see?",
    "Is there a cure for cancer?",
    "Is there a war going on?",
    "Is space tourism open yet?",
    "What are you doing?",
    "Tell us, tell us.",
  ];

  const [index, setIndex] = useState(0);

  useEffect(() => {
    const timer = () => {
      setIndex((prevIndex) => {
        if (prevIndex === hints.length - 1) {
          return 0;
        }

        return prevIndex + 1;
      });
    };
    const interval = setInterval(timer, 4000);

    //cleanup function in order clear the interval timer
    //when the component unmounts
    return () => {
      clearInterval(interval);
    };
  }, []);

  const charactersLeft = maxAnswerLength - answer.length;

  return (
    <PageTemplate>
      <div className={cx("question-screen")}>
        <div className={cx("question")}>{prompts[currentPromptIndex.current]}</div>
        <div className={cx("hint")}>{hints[index]}</div>

        <div className={cx("answer-input")}>
          <textarea
            placeholder=""
            maxLength={maxAnswerLength}
            rows={5}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
              handleChange(e.target.value);
            }}
          />
          <div className={cx("answer-input__characters-left")}>
            {charactersLeft === 1
              ? `${charactersLeft} character left`
              : `${charactersLeft} characters left`}
          </div>
        </div>
        <p>No profanity and hate-speech allowed.</p>

        <div className="mt32">
          {answer.length > 0 ? (
            <button className={cx("next-button")} onClick={handleNext}>
              Next
            </button>
          ) : (
            <button className={cx("next-button")} disabled>
              Next
            </button>
          )}
        </div>
      </div>
    </PageTemplate>
  );
};

export default Question1;
